<script setup lang="ts">
/**
 * The Footer
 * --------------------------------------------------------------------------------------------------
 *
 * Main app footer. Uses global state, so you can show/hide/adjust anytime
 * Can either show as a white bar or transparent with only round (floating) next button
 */

const isSubFlow = inject('isSubFlow', false);

const {
    isEnabled,
    isPriceVisible,
    backwardCallback,
    backwardLabel,
    canGoBack,
    layout,
} = useFooterState();

const { width: viewportWidth } = useWindowSize({ includeScrollbar: false });

const styles = computed(() => {
    const basicStyles = {
        '--w-viewport': `${viewportWidth.value}px`,
        '--w-viewport-half': `${viewportWidth.value / 2}px`,
        '--w-container': 'calc(var(--w-viewport) - (var(--w-container-gap) * 2))',
        '--w-footer': 'calc(var(--w-container) - var(--w-aside) - var(--w-aside-main-gap))',
        '--offset-x-layout-aside': 'calc(var(--w-container-gap) + (var(--w-aside) + var(--w-aside-main-gap)))',
        '--offset-x-layout-checkout': 'calc(var(--w-viewport-half) - (var(--w-max-checkout) / 2))',
        '--offset-x-layout-prose': 'calc(var(--w-viewport-half) - (var(--w-max-prose) / 2))',
    };

    return basicStyles;
});

const classes = computed(() => {
    const basicClasses = [
        'fixed',
        'z-[25]',
        'bottom-0',
        'md:bottom-4',
        'flex',
        'w-full',
        'h-[65px]',
        'md:h-[60px]',
        'items-center',
        'justify-between',

    ];

    const whiteBarClasses = [
        'bg-white',
        'px-container-gap',
        'py-2',
        'border-t',
        'border-shade-1',
        'md:border-t-0',
        'md:rounded',
        'md:pr-2',
        'md:pl-4',
        isSubFlow ? 'footer-shadow-subflow' : 'footer-shadow-default',
    ];

    const floatingClasses = [
        '!w-[64px]',
        '!translate-x-0',
        'right-container-gap',
        'max-md:pb-6',
    ];

    const asidePosClasses = [
        'md:w-[var(--w-footer)]',
        'md:max-w-[var(--w-footer)]',
        'md:translate-x-[var(--offset-x-layout-aside)]',
    ];

    switch (layout.value) {
        case 'centerCheckout':
            return [
                ...basicClasses,
                ...whiteBarClasses,
                'md:w-[var(--w-max-checkout)]',
                'md:translate-x-[var(--offset-x-layout-checkout)]',
                'md:transition-all',
                'md:duration-500',
            ];
        case 'centerProse':
            return [
                ...basicClasses,
                ...whiteBarClasses,
                'md:w-[var(--w-max-prose)]',
                'md:translate-x-[var(--offset-x-layout-prose)]',
            ];
        case 'floating':
            return [
                ...basicClasses,
                ...asidePosClasses,
                ...floatingClasses,
            ];
        case 'withSidebar':
        default:
            return [
                ...basicClasses,
                ...asidePosClasses,
                ...whiteBarClasses,
            ];
    }
});
</script>

<template>
    <footer
        v-if="isEnabled"
        :class="classes"
        :style="styles"
    >
        <div v-if="layout !== 'floating'" class="h-full">
            <Transition name="fade">
                <TheFooterPrice v-if="isPriceVisible" />
            </Transition>
        </div>

        <div class="flex min-[400px]:space-x-4">
            <button
                v-if="!!backwardCallback"
                :disabled="!canGoBack"
                class="justify-self-end px-4 transition-colors hover:text-shade-5 disabled:text-shade-4"
                @click="backwardCallback"
            >
                <span class="typo-button max-sm:hidden">
                    {{ backwardLabel.length ? backwardLabel : $t('core.buttons.back') }}
                </span>
                <Icon name="tot:backward" class="size-4 sm:hidden" :aria-label="$t('core.buttons.back')" />
            </button>

            <TheFooterProceedButton />
        </div>
    </footer>
</template>

<style scoped>
.footer-shadow-subflow {
    @apply md:shadow-[0px_0px_20px_0px_#24356226,0px_20px_10px_12px_theme('colors.background-3')];
}

.footer-shadow-default {
    @apply md:shadow-[0px_0px_20px_0px_#24356226,0px_20px_10px_12px_theme('colors.background-1')];
}
</style>
